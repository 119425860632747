import { createApp } from 'vue'
import axios from 'axios'
import vuelazyload from 'vue-lazyload'
import App from './App.vue'
import neirong from './components/mains/neirong.vue'
import teachers from './components/teachers/teachers.vue'
import jj1 from './components/jianjie/jj1.vue'
import jj2 from './components/jianjie/jj2.vue'
import jj3 from './components/jianjie/jj3.vue'
import lianxi from './components/mains/contact/lianxi.vue'
import gongzuotai from './components/gongzuotai/gongzuotai.vue'
import kecheng from './components/kechengs/kecheng.vue'
import zhanghu from './components/gongzuotai/zhanghus/zhanghu.vue'
import dianmingbiao from './components/gongzuotai/dianmingbiao/dianmingbiao1.vue'
import kaojibaoming from './components/gongzuotai/kaojibisai/kaojibaoming.vue'
import zhuce from './components/zhuce.vue'
import { createRouter, createWebHistory } from 'vue-router'
import score from './store/index.js'
import denglu from './components/denglu.vue'
import findpassword from './components/findpassword.vue'
import shuzhizhixing from './components/gongzuotai/kaojibisai/shuzhizhixing.vue'
import keshijiaofeijilu from './components/gongzuotai/jiaofeijilu/keshijiaofeijilu.vue'
import kaojijiaofeijilu from './components/gongzuotai/jiaofeijilu/kaojijiaofeijilu.vue'
import bisaijiaofeijilu from './components/gongzuotai/jiaofeijilu/bisaijiaofeijilu.vue'
import goumaijiaofeijilu from './components/gongzuotai/jiaofeijilu/goumaijiaofeijilu.vue'
import familys from './components/gongzuotai/zhanghus/familys.vue'
import chongzhi from './components/gongzuotai/zhanghus/chongzhi.vue'
import gaifamily from './components/gongzuotai/zhanghus/gaifamily.vue'
import ketangzhibo from './components/gongzuotai/zhibo/ketangzibo.vue'
import linkfail from './components/linkfail.vue'
import VueLazyload from 'vue-lazyload'

const routes = [
	{
		path: '/',
		name: 'neirong',
		component: neirong
	},
	{
		path: '/teachers',
		name: 'teachers',
		component: teachers
	},
	{
		path: '/linkfail',
		name: 'linkfail',
		component: linkfail
	},
	{
		path: '/jj1',
		name: 'jj1',
		component: jj1
	},
	{
		path: '/jj2',
		name: 'jj2',
		component: jj2
	},
	{
		path: '/jj3',
		name: 'jj3',
		component: jj3
	},
	{
		path: '/kecheng',
		name: 'kecheng',
		component: kecheng
	},
	{
		path: '/lianxi',
		name: 'lianxi',
		component: lianxi
	},
	{
		path: '/gongzuotai',
		name: 'gongzuotai',
		component: gongzuotai,
		children: [
			{
				path: '/zhanghu',
				name: 'zhanghu',
				component: zhanghu
			},
			{
				path: '/dianmingbiao', // 使用动态占位符:day
				name: 'dianmingbiao',
				component: dianmingbiao
			},
			{
				path: '/kaojibaoming',
				name: 'kaojibaoming',
				component: kaojibaoming
			},
			{
				path: '/shuzhizhixing',
				name: 'shuzhizhixing',
				component: shuzhizhixing
			},
			{
				path: '/keshijiaofeijilu',
				name: 'keshijiaofeijilu',
				component: keshijiaofeijilu
			},
			{
				path: '/kaojijiaofeijilu',
				name: 'kaojijiaofeijilu',
				component: kaojijiaofeijilu
			},
			{
				path: '/bisaijiaofeijilu',
				name: 'bisaijiaofeijilu',
				component: bisaijiaofeijilu
			},
			{
				path: '/goumaijiaofeijilu',
				name: 'goumaijiaofeijilu',
				component: goumaijiaofeijilu
			},
			{
				path: '/familys',
				name: 'familys',
				component: familys
			},
			{
				path: '/chongzhi',
				name: 'chongzhi',
				component: chongzhi
			},
			{
				path: '/gaifamily',
				name: 'gaifamily',
				component: gaifamily
			},
		]
	},
	{
		path: '/zhuce',
		name: 'zhuce',
		component: zhuce
	},
	{
		path: '/denglu',
		name: 'denglu',
		component: denglu
	},
	{
		path: '/findpassword',
		name: 'findpassword',
		component: findpassword
	},
	{
		path: '/ketangzhibo',
		name: 'ketangzhibo',
		component: ketangzhibo
	}

]
const router = createRouter({
	history: createWebHistory(),
	routes
})

const app = createApp(App)
app.config.globalProperties.$axios = axios;
app.use(router)
app.use(score)
app.use(vuelazyload, {
	preLoad: 1.3, // 图片高度的1.3倍提前加载
	// error: 'error.jpg', // 加载错误时显示的图片
	// loading: 'loading.gif', // 加载中显示的图片
	attempt: 3 // 尝试加载图片的次数
})
app.mount('#app')

