<template>
	<section  class="course">
	    <h2>培训课程</h2>
	    <div  class="course-list">
	        <courselist v-for="(p,index) in item" :key="index" :name="p.name" :des="p.des" :hrefs="p.hrefs"></courselist>
	    </div>
	</section>
</template>

<script setup>
	import courselist from './courselist/courselist.vue'
	const item=[{name:"绘画班",des:"适合喜爱绘画的同学",hrefs:"#"},
	{name:"硬笔班",des:"适合零基础的学习者",hrefs:"#"},
	{name:"软笔班",des:"适合已掌握基础书法的学习者",hrefs:"#"}]
</script>

<style>
	.course  {
	    padding:  50px;
	    background-color:  #f2f2f2;
	}
	
	.course  h2  {
	    font-size:  36px;
	    margin:  0;
	}
	
	.course-list  {
	    display:  flex;
	    justify-content:  space-between;
	    flex-wrap:  wrap;
	    margin-top:  50px;
	}
	@media  (max-width:  768px)  {
	    /*  响应式布局  */
	    .course-list  {
	        flex-direction:  column;
	    }
	
	    .course-card  {
	        width:  100%;
	        margin:  20px  0;
	    }
	
	}
</style>