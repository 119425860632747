<template>
  <div class="sidebar">

    <ul class="sidebar-menu">
      <li @click="bool0 = !bool0; $forceUpdate()">
        <router-link class="rout" to="zhanghu">我的账户</router-link>
        <ul class="submenu" v-if="bool0">
          <li><router-link class="rout" to="familys" v-if="$store.state.isstudent">我的家庭</router-link></li>
        </ul>
      </li>
      <li @click="bool1 = !bool1; $forceUpdate()">
        <router-link class="rout" to="">考级比赛</router-link>
        <ul class="submenu" v-if="bool1">
          <li><router-link class="rout" to="kaojibaoming">考级报名表</router-link></li>
          <li><router-link class="rout" to="shuzhizhixing">素质之星报名表</router-link></li>
          <li><router-link class="rout" to="">米芾杯报名表</router-link></li>
          <li><router-link class="rout" to="">笔墨中国报名表</router-link></li>
        </ul>
      </li>
      <li @click="bool2 = !bool2; $forceUpdate()">
        <router-link class="rout" to="">交费记录</router-link>
        <ul class="submenu" v-if="bool2">
          <li><router-link class="rout" to="keshijiaofeijilu">课时充值</router-link></li>
          <li><router-link class="rout" to="kaojijiaofeijilu">考级报名充值</router-link></li>
          <li><router-link class="rout" to="bisaijiaofeijilu">比赛报名充值</router-link></li>
          <li><router-link class="rout" to="goumaijiaofeijilu">购买记录</router-link></li>
        </ul>
      </li>

      <li @click="bool3 = !bool3; $forceUpdate()">
        <router-link class="rout" to="">复印稿</router-link>
        <ul class="submenu" v-if="bool3">
          <li><router-link class="rout" to="">毛笔字样</router-link></li>
          <li><router-link class="rout" to="">考级/比赛报名表</router-link></li>
          <li><router-link class="rout" to="">点名表</router-link></li>
          <li><router-link class="rout" to="">画画样图</router-link></li>
          <li><router-link class="rout" to="" v-if="!$store.state.isstudent">消防表单</router-link></li>
          <li><router-link class="rout" to="" v-if="!$store.state.isstudent">其他表单</router-link></li>
        </ul>
      </li>

      <li><router-link class="rout" to="" v-if="!$store.state.isstudent">销货记录表</router-link></li>
      <li @click="bool4 = !bool4; $forceUpdate()">
        <router-link class="rout" to="dianmingbiao">学生点名表</router-link>
      </li>
      <li><router-link class="rout" to="ketangzhibo">课堂直播</router-link></li>
    </ul>
  </div>
</template>
<script setup>
let bool1 = false
let bool2 = false
let bool3 = false
let bool4 = false
let bool0 = false
</script>
<style scoped>
.sidebar {
  top: 0;
  left: 0;
  height: 100%;
  width: 200px;
  background-color: #F0F0F0;
}

.sidebar-menu {
  list-style: none;
  margin: 0;
  padding: 0;
}

.sidebar-menu li {
  border-bottom: 1px solid #D0D0D0;
}

.rout {
  display: block;
  padding: 10px;
  text-decoration: none;
  color: #333;
}

.rout:hover {
  background-color: #DDD;
}


.submenu li {
  border-bottom: none;
}


</style>