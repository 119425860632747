<template>
	<header>
	    <h1>{{title1}}</h1>
	    <nav>
	        <ul>
	            <li v-for="(p,index) in dhbtns" :key="index"><dhben :name='p.name' :hrefs='p.hrefs'></dhben></li>
	        </ul>
	    </nav>
	</header>

</template>

<script setup>
import dhben from './dhben.vue'
	let title1 ="无锡市水云书斋艺术培训服务部"
	const dhbtns=[{id:"1",name:"首页",hrefs:"/"},
	{id:"2",name:"培训课程",hrefs:"kecheng"},
	{id:"3",name:"教学团队",hrefs:"jj1"},
	{id:"4",name:"学员作品",hrefs:"teachers"},
	{id:"5",name:"联系我们",hrefs:"lianxi"},
	{id:"6",name:"工作台",hrefs:"zhanghu"}]
</script>

<style scoped>
	header{
		background-color: #c1e1ec;
		color: white;
	}
	h1{
		font-family: "STXINGKA", cursive;
		font-size: 70px;
	}
	ul{
		display: flex;
		justify-content: center;
		align-items: center;
	}
	ul li {
	    background-color:#fff9b1;
	    display: inline-block;
	    width: 25%;
	    text-align: center;
	}
	ul li:hover {
	  background-color:lavender;
	}
</style>