<template>
	<Qingdenglu />
	<form v-if="$store.state.isdenglu">
		<h1>考级报名表</h1>
		<label for="name">姓名</label>
		<select name="name" id="name" v-model="job" required>
			<option value=""></option>
			<option v-for="(member,index) in $store.state.familyMembers.slice(-4)" :key="index" :value="member.job">{{ member.name }}</option>
		</select>

		<label for="id_number">身份证号码</label>
		<input type="text" name="id_number" id="id_number" v-model="sid" required>

		<label for="name">姓名拼音</label>
		<input type="text" name="namepy" id="namepy" v-model="pingying" required>
		<label for="subject">种族</label>
		<input type="text" name="zhongzu" id="zhongzu" v-model="zhongzu" required>


		<label for="subject">考级科目</label>
		<select name="subject" id="subject" v-model="courseid" required>
			<option value=""></option>
			<option v-for="member in $store.state.courses" :key="member.id" :value="member.id">{{ member.name }}</option>
		</select>

		<label for="level">考级等级</label>
		<select name="level" id="level" v-model="level" required>
			<option value=""></option>
			<option value="1">一级</option>
			<option value="2">二级</option>
			<option value="3">三级</option>
			<option value="4">四级</option>
			<option value="5">五级</option>
			<option value="6">六级</option>
			<option value="7">七级</option>
			<option value="8">八级</option>
			<option value="9">九级</option>
			<option value="10">十级</option>
		</select>
		<label for="subject">请选择考级时间</label>
		<datachoose></datachoose>
		<button type="submit" id="submit" @click.prevent="kaojibaoming">提交</button>
	</form>
</template>

<script >
import Qingdenglu from '../qingdenglu.vue';
import datachoose from './datachoose.vue'
export default{
	data() {
    return {
		job:"",
		courseid:'',
		level:'',
		sid:'',
		pingying:'',
		zhongzu:''
    };
  },
  methods:{

	kaojibaoming(){
		const params={
			familyid:this.$store.state.familysid,
			job:this.job,
			course:this.courseid,
			level:this.level,
			pingying:this.pingying,
			zhongzu:this.zhongzu,
			sid:this.sid,
			time:this.$store.state.datachoose+this.$store.state.jijiechoose
		}
		this.$axios.get(this.$store.state.url+'/kaojibaoming',{params}) // 替换为你的 GET 请求的 API 端点
                    .then(response => {
						if(response.data=='success'){
							this.$forceUpdate
							alert('报名成功,请准备好其他材料')
						}else if(response.data=='22'){
							alert('有重复报名记录，报名失败')
						}
					})
                    .catch(error => {this.$router.push({ name: 'linkfail' })});
	}
  },
  components: { Qingdenglu, datachoose}
}

</script>

<style scoped>
form {
  width: 200%;
  margin: auto;
  font-family: Arial, sans-serif;
  font-size: 16px;
}

input {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  box-sizing: border-box;
  border: 2px solid #ddd;
  border-radius: 4px;
}

select {
  width: 31%;
  padding: 10px;
  margin-right: 3%;
  box-sizing: border-box;
  border: 2px solid #ddd;
  border-radius: 4px;
}

label {
  display: block;
  margin-bottom: 10px;
  font-weight: bold;
}

#submit {
  background-color: blue;   /* 设置背景颜色为蓝色 */
  color: white;
  font-size: 18px;   /* 放大字体大小 */
  width: 100%;   /* 按钮宽度为100% */
  padding: 15px 20px;   /* 修改按钮内边距 */
  margin-top: 10px;   /* 添加顶部边距 */
  bottom: 0;   /* 按钮置底 */
  left: 0;   /* 按钮置左 */
}

</style>