<template>
	<section class="register-form">
		<h1>注册</h1>
		<form>
			<label for="username">真实姓名：</label>
			<input v-model="username" type="text" id="username" name="username" required><br>
			<p>{{ name1 }}</p>
			<label for="phone">手机号：</label>
			<input v-model="phone" type="number" id="phone" name="phone" required><br>
			<p>{{ phone1 }}</p>
			<label>短信验证码：</label>
			<input v-model="phonecode" type="number" required><br>
			<input type="submit" value="获取验证码" @click.prevent="phonegetcode"><br>
			<label for="password">密码：</label>
			<input v-model="password" type="password" id="password" name="password" required><br>
			<p>{{ password1 }}</p>
			<label for="confirm_password">确认密码：</label>
			<input v-model="confirmPassword" type="password" id="confirm_password" name="confirm_password" required><br>
			<p>{{ password2 }}</p>
			<!-- 职业选择 -->
			<label for="occupation">您的职业：</label>
			<select v-model="occupation" id="occupation" name="occupation" @change="handleOccupationChange">
				<option value="">请选择职业</option>
				<option value="rector">校长</option>
				<option value="teacher">老师</option>
				<option value="parent">家长</option>
				<option value="student">学生</option>
			</select>

			<!-- 家长关系选择 -->
			<div v-if="occupation === 'parent'">
				<label for="parent_relation">家长关系：</label>
				<select v-model="parentRelation" id="parent_relation" name="parent_relation">
					<option value="">请选择家长关系</option>
					<option value="father">爸爸</option>
					<option value="mather">妈妈</option>
					<option value="grandfather">爷爷</option>
					<option value="grandmather">奶奶</option>
					<option value="mgrandfather">外公</option>
					<option value="mgrandmather">外婆</option>
				</select>
			</div>

			<input type="submit" value="注册" @click.prevent="validateForm">
		</form>
	</section>
</template>

<script>
export default {
	data() {
		return {
			username: '',
			phone: '',
			password: '',
			confirmPassword: '',
			name1: '',
			phone1: '',
			password1: '',
			password2: '',
			occupation: '', // 职业选择项
			parentRelation: '', // 家长关系选择项
			phonecode: ''
		}
	},
	watch: {
		username: function (newValue) {
			this.validateUsername(newValue);
		},
		phone: function (newValue) {
			this.validatePhone(newValue);
		},
		password: function (newValue) {
			this.validatePassword(newValue);
		},
		confirmPassword: function (newValue) {
			this.validateConfirmPassword(newValue);
		},
	},
	methods: {
		phonegetcode() {
			if (this.phone === '') {
				alert('手机号不能为空')
			} else {
				this.$axios.get(`${this.$store.state.url}/yanzhengma?phone=${this.phone}`)
					.then(response => {
						localStorage.setItem('yanzhengTime', Date.now());
						localStorage.setItem('yanzhengma', response.data);
					})
					.catch(error => {
						this.$router.push({ name: 'linkfail' })
					});
			}
		},
		validateForm() {
			if (this.username === '' || this.phone === '' || this.password === '' || this.confirmPassword === '' || this.occupation === '') {
				alert('有输入框为空，注册失败');
				return;
			} else if (this.phone1 !== '' || this.password1 !== '' || this.password2 !== '') {
				alert('输入不符合要求，注册失败');
				return;
			} else if (this.occupation === 'parent' && this.parentRelation === '') {
				alert('请选择您的家庭职称，注册失败');
				return;
			} else {
				const now = new Date();
				const yanzhengTime = localStorage.getItem('yanzhengTime');
				const timeDiff = now - new Date(yanzhengTime);
				const minutesDiff = Math.floor(timeDiff / 1000 / 60);

				if (minutesDiff > 5) {
					alert('验证码超时，注册失败');
					return;
				}

				if (localStorage.getItem('yanzhengma') !== this.phonecode) {
					alert('输入的验证码错误，注册失败');
					return;
				}

				const job = (this.occupation !== 'parent') ? this.occupation : this.parentRelation;

				const params = {
					name: this.username,
					job: job,
					phone: this.phone,
					password: this.password
				};

				this.$axios.get(this.$store.state.url+'/zhuce', { params })
					.then(response => {
						if (response.data === 'error22') {
							alert('您注册的电话号码已经存在，注册失败');
						} else {
							console.log(response.data);
							localStorage.setItem('job', job);
							localStorage.setItem('phone', this.phone);
							localStorage.setItem('password', this.password);
							localStorage.setItem('registrationTime', Date.now());
							this.$store.state.data = response.data;
							console.log(this.$store.state.data);
							this.$router.push('/zhanghu');
						}
					})
					.catch(error => {this.$router.push({ name: 'linkfail' })});
			}
		},
		validateUsername(username) {
			// 进行用户名验证逻辑
			// 在这里添加你的验证逻辑，例如检查长度、特殊字符等

		},
		validatePhone(phone) {
			// 进行手机号验证逻辑
			// 在这里添加你的验证逻辑，例如检查长度、格式等
			let phones = phone.toString()
			if (phones.length !== 11) {
				this.phone1 = "手机号长度应为11";
			} else {
				this.phone1 = '';
			}
		},
		validatePassword(password) {
			// 进行密码验证逻辑
			// 在这里添加你的验证逻辑，例如检查长度、强度等
			if (this.password.length < 6) {
				this.password1 = "密码的长度不能小于6";
			} else {
				this.password1 = "";
			}
			this.validateConfirmPassword(this.confirmPassword)
		},
		validateConfirmPassword(confirmPassword) {
			if (this.password !== confirmPassword) {
				this.password2 = "两次输入的密码不同";
			} else {
				this.password2 = "";
			}
		},
		handleOccupationChange() {
			// 当职业选择项变化时，重置家长关系选择项
			if (this.occupation !== 'parent') {
				this.parentRelation = '';
			}
		}
	}
}
</script>

<style scoped>
p {
	color: red;
}

body {
	font-family: Arial, Helvetica, sans-serif;
	margin: 0;
	padding: 0;
	background-color: #F3F3F3;
}

.register-form {
	margin: 50px auto;
	padding: 20px;
	background: #FFFFFF;
	border-radius: 10px;
	width: 400px;
}

h1 {
	text-align: center;
	margin-bottom: 20px;
	color: #333333;
}

form {
	display: flex;
	flex-direction: column;
}

label {
	margin-top: 10px;
	color: #333333;
	font-size: 16px;
}

input {
	padding: 10px;
	margin: 10px 0;
	border-radius: 5px;
	border: 1px solid #BBBBBB;
}

input[type="submit"] {
	background-color: #4CAF50;
	color: #FFFFFF;
	border: none;
	padding: 10px 20px;
	margin-top: 10px;
	border-radius: 5px;
	font-size: 16px;
	cursor: pointer;
}

input[type="submit"]:hover {
	background-color: #3E8E41;
}
</style>