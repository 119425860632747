<template>
  <div class="container">
    <!-- 左侧导航栏 -->
    <div class="sidebar">
      <ul class="nav-links">
        <li v-for="(link, index) in navigationLinks" :key="index">
          <button @click="performSearch">{{ link.text }}</button>
        </li>
      </ul>
    </div>

    <main class="main-content">
      <!-- 屏幕/中间内容 -->
      <router-view></router-view>
      <!-- 在模板中添加连接状态显示 -->
      <div v-if="!isConnected" class="connection-status">WebSocket 连接断开...</div>
      <div v-else class="connection-status">WebSocket 连接已建立！</div>
      <!-- 显示实时图像 -->
      <img v-if="base64Image" :src="base64Image" alt="Real-time Image from ESP32" />
    </main>
  </div>
</template>

<script>
import SockJS from 'sockjs-client';
import { Stomp } from '@stomp/stompjs';

export default {
  data() {
    return {
      searchText: '',
      navigationLinks: [
        { text: '摄像头1', path: '/' },
        { text: '摄像头2', path: '/news' },
        { text: '摄像头3', path: '/products' },
      ],
      base64Image: '',
      isConnected: false, // 新增：连接状态属性
    };
  },
  mounted() {
    // 需要替换为实际的WebSocket URL
    const socket = new WebSocket('ws://localhost:8800/websocket');
// 其余部分保持不变

    // 创建Stomp客户端实例并将其赋值给一个变量
    const client = Stomp.over(socket);

    // 定义连接成功回调
    const connectCallback = (frame) => {
      console.log('Connected to WebSocket: ' + frame);
      this.isConnected = true; // 设置连接状态为已连接
      // 订阅主题
      client.subscribe('/topic/imageData', (message) => {
        this.base64Image = message.body;
      });
    };

    // 定义连接失败回调
    const errorCallback = (error) => {
      console.error('STOMP Connection Error:', error);
      this.isConnected = false; // 设置连接状态为未连接
    };

    // 连接 STOMP 客户端
    client.connect({}, connectCallback, errorCallback);
  },
  methods: {
    performSearch() {
      return;
    },
  },
};
</script>

<style scoped>
.container {
  display: flex;
}

.sidebar {
  width: 200px;
  height: 100vh;
  background-color: #f5f5f5;
  padding: 20px;
}

.nav-links {
  list-style-type: none;
  padding: 0;
}

.main-content {
  flex-grow: 1;
  padding: 20px;
}

.search-bar {
  margin-bottom: 20px;
}

.search-bar input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
}

.search-bar button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
}
</style>