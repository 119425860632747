<template>
	<section  class="banner">
	    <h2>软笔字书法培训</h2>
	    <p>学习软笔字书法，提高审美水平，让你的书法更上层楼。</p>
	    <button>立即报名</button>
	</section>
</template>

<script setup>
</script>

<style scoped>
	.banner  {
	    background-size:  cover;
	    background-position:  center;
	    height:  400px;
	    display:  flex;
	    flex-direction:  column;
	    justify-content:  center;
	    align-items:  center;
	    text-align:  center;
	}
	
	.banner  h2  {
	    font-size:  48px;
	    margin:  0;
	}
	
	.banner  p  {
	    font-size:  24px;
	    margin:  20px  0;
	}
	
	.banner  button  {
		color: white;
	    border:  none;
	    background-color:  darkgray;
	    padding:  10px  20px;
	    font-size:  18px;
	    border-radius:  30px;
	}
	.banner  button:hover {
	    color: black;
	    background-color:  #A9A9A9;
	}
</style>