<template>
  <div>
    <h1>填写家庭成员</h1>
    <form @submit.prevent="submitForm">
      <div v-for="(member, index) in $store.state.familyMembers" :key="index">
        <label :for="index">{{ member.label }}:</label>
        <input type="text" :id="index" v-model="member.name"  @input="generateOptions(index)">
        <select v-if="member.showOptions" v-model="member.choose">
          <option v-for="option in member.options" :key="option" :value="option">{{ option }}</option>
        </select>
      </div>
      <button type="submit">提交</button>
    </form>
  </div>
</template>
  
<script>
import axios from 'axios';
export default {
  data() {
    return {
    };
  },
  methods: {
    submitForm() {
      const postData = this.$store.state.familyMembers;
      const id=this.$store.state.familysid
      for(p in this.$store.state.familysid){
        if(p.showOptions&&p.choose){
          alert("请选中需要修改的家人的电话号码，修改失败")
          return
        }
        for(p2 in this.$store.state.familysid){
          if(p2.showOptions&&!p1.showOptions&&p2.name==p1.name){
            alert("同一个家庭不能出现名字完全相同的人,修改失败")
            return
          }
        }
      }
      axios.post('http://localhost:8080/gaifamily', postData,id)
        .then(response => {
          // 处理成功响应
          console.log(response.data);
          alert("修改成功")
          this.$store.state.isfamilymember=false
        })
        .catch(error => {
          this.$router.push({ name: 'linkfail' }) 
        });
    },
    generateOptions(index) {
      const member = this.$store.state.familyMembers[index];
      if (member.name !== '') {
        member.showOptions = true;""
        let job=this.$store.state.familyMembers[index].job
        if(job=="children1"||job=="children2"||job=="children3"||job=="children4"){
          job="student"
        }
        axios.get(`http://localhost:8080/findname?name=${this.$store.state.familyMembers[index].name}&job=${job}`) // 替换为你的 GET 请求的 API 端点
          .then(response => {
            // 处理成功响应的数据
            member.options = response.data;
          })
          .catch(error => {
            this.$router.push({ name: 'linkfail' }) 
          });
        
      } else {
        member.showOptions = false;
        member.options = [];
      }
    }
  }
};
</script>
  
<style scoped>
h1 {
  text-align: center;
  margin-bottom: 20px;
}

form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

label {
  margin-bottom: 10px;
}

input[type="text"],
select {
  padding: 5px;
  margin-bottom: 10px;
  width: 200px;
}

button[type="submit"] {
  padding: 10px 20px;
  background-color: #4CAF50;
  color: white;
  border: none;
  cursor: pointer;
}

button[type="submit"]:hover {
  background-color: #45a049;
}

.container {
  max-width: 500px;
  margin: 0 auto;
}
</style>