<template>
    <div class="server-error-page">
        <h1>无法连接到服务器</h1>
        <p>抱歉，我们无法连接到服务器。请检查您的网络连接，然后尝试重新加载页面。</p>
        <button @click="retryConnection">返回主页</button>
    </div>
</template>
  
<script>
export default {
    methods: {
        retryConnection() {
            // 实现重试逻辑，可能是重新加载页面或重新发起请求
            this.$router.push({ name: 'neirong' })
        }
    }
}
</script>
  
<style scoped>
.server-error-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 80vh;
    padding: 20px;
    text-align: center;
    background-color: #f8f8f8;
}

.server-error-page h1 {
    color: #d32f2f;
    /* 红色调用以突出显示错误 */
    margin-bottom: 20px;
    font-size: 2em;
}

.server-error-page p {
    color: #666;
    margin-bottom: 30px;
    font-size: 1em;
    max-width: 600px;
    /* 限制文本宽度 */
}

.server-error-page button {
    background-color: #4285f4;
    /* 明亮的按钮颜色 */
    color: white;
    border: none;
    padding: 10px 20px;
    text-transform: uppercase;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1em;
    transition: background-color 0.3s;
}

.server-error-page button:hover {
    background-color: #3079ed;
    /* 鼠标悬停时改变按钮颜色 */
}
</style>
  