/**
 * 登录接口
 * @param {Object} store - Vuex的store对象
 * @param {string} phone - 用户手机号
 * @param {string} password - 用户密码
 * @param {string} registrationTime - 注册时间
 */
export function denglu(store,phone, password, registrationTime) {
    console.log('denglu');
    if (store.state.isdenglu == true) { return; }
    if (registrationTime) {
        const currentTime = Date.now();
        const timePassed = currentTime - parseInt(registrationTime);
        const daysPassed = Math.floor(timePassed / (1000 * 60 * 60 * 24));
        if (daysPassed > 15) {
            console.log('登录过期');
            return;
        }
    }
    if (phone && password) {
        this.$axios.get(`${store.state.url}/denglu?phone=${phone}&password=${password}`)
            .then(response => {
                if (response.data) {
                    store.state.items = response.data; // 将获取的数据赋值给 items 属性
                    const jobs = response.data.job;
                    const ids = response.data.id;
                    store.state.isdenglu = true;
                    localStorage.setItem('registrationTime', Date.now()); // 更新localStorage中的时间戳
                    let axiosPromise;
                    if (jobs === 'teacher') {
                        axiosPromise =  this.$axios.get(`${store.state.url}/dengluteacher?id=${ids}`);
                        store.state.isteacher = true;
                    } else if (jobs === 'rector') {
                        store.state.isrector = true;
                        axiosPromise =  this.$axios.get(`${store.state.url}/denglurector?id=${ids}`);
                    } else {
                        axiosPromise =  this.$axios.get(`${store.state.url}/denglustudent?id=${ids}&job=${jobs}`);
                        store.state.isstudent = true;
                    }
                    axiosPromise
                        .then(response => {
                            store.state.items2 = response.data;
                            if (store.state.isstudent) {
                                this.$axios.get(`${store.state.url}/findhome?id=${store.state.data}`) // 替换为你的 GET 请求的 API 端点
                                    .then(response => {
                                        // 处理成功响应的数据
                                        store.state.familysid = response.data.id;
                                        store.state.familyMembers[0].name = response.data.father;
                                        store.state.familyMembers[1].name = response.data.mather;
                                        store.state.familyMembers[2].name = response.data.grandfather;
                                        store.state.familyMembers[3].name = response.data.grandmather;
                                        store.state.familyMembers[4].name = response.data.mgrandfather;
                                        store.state.familyMembers[5].name = response.data.mgrandmather;
                                        store.state.familyMembers[6].name = response.data.children1;
                                        store.state.familyMembers[7].name = response.data.children2;
                                        store.state.familyMembers[8].name = response.data.children3;
                                        store.state.familyMembers[9].name = response.data.children4;
                                        store.state.isfamilymember = true
                                    })
                                    .catch(error => {
                                        this.$router.push({ name: 'linkfail' }) 
                                    });
                            }
                        })
                        .catch(error => {
                            this.$router.push({ name: 'linkfail' }) 
                        });
                } else {
                    console.log('error  denglu返回内容为空')
                }
            })
            .catch(error => {
                this.$router.push({ name: 'linkfail' }) 
            });
    } else {
        return
    }
};
