<template>
  <div class="parent">
    <div v-if="$store.state.isdenglu" class="user-info">
      <div class="avatar">
        <img v-lazy="imagePath" alt="头像">
      </div>
      <div class="user-details">
        <p>用户名: {{ $store.state.name }}</p>
        <p>职业: {{ $store.state.job }}</p>
      </div>
    </div>
    <div v-if="!$store.state.isdenglu" class="links">
      <router-link to="zhuce" class="link">注册</router-link>
      <router-link to="denglu" class="link">登录</router-link>
    </div>
    <div class="links" v-if="$store.state.isdenglu">
      <button @click="$store.state.isdenglu=false">退出登录</button>
    </div>
  </div>
  <h2>课程信息</h2>
  <div class="child-info">
    <table>
      <tr>
        <th>编号</th>
        <th v-if="$store.state.isstudent">学生姓名</th>
        <th>教育机构</th>
        <th v-if="$store.state.isteacher || $store.state.isstudent">课程名称</th>
        <th v-if="$store.state.isteacher">上课时间</th>
        <th v-if="$store.state.isstudent">剩余课时</th>
      </tr>
      <tr v-for="(item, index) in $store.state.items2" :key="index">
        <td>{{ index + 1 }}</td>
        <td v-if="$store.state.isstudent">{{ item.nameid }}</td>
        <td>{{ item.schoolid }}</td>
        <td v-if="$store.state.isteacher || $store.state.isstudent">{{ item.courseid }}</td>
        <td v-if="$store.state.isteacher">{{ item.timeid }}</td>
        <td v-if="$store.state.isstudent">{{ item.Remainingnum }}</td>
        <td v-if="$store.state.isstudent"><router-link to="chongzhi">充值</router-link></td>
      </tr>
      <tr>
        <td><router-link to="">添加</router-link></td>
        <td v-if="$store.state.isstudent"><router-link to="">添加</router-link></td>
        <td><router-link to="">添加</router-link></td>
        <td v-if="$store.state.isteacher || $store.state.isstudent"><router-link to="">添加</router-link></td>
        <td v-if="$store.state.isteacher"><router-link to="">添加</router-link></td>
        <td v-if="$store.state.isstudent"><router-link to="">添加</router-link></td>
      </tr>
    </table>
  </div>
</template>


<script>
export default {
  name: 'MyComponent',
  data(){
    return{
      imagePath:"@/assets/images/head.jpg"
    }
  }
};
</script>
<style scoped>
.parent {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.user-info {
  display: flex;
  align-items: center;
  margin-right: 20px;
  /* 调整这个数值来调整头像框和用户名之间的距离 */
}

.avatar {
  width: 50px;
  /* 调整这个数值来调整头像框的大小 */
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
}

.avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.user-details {
  margin-left: 10px;
  /* 调整这个数值来调整用户名和职业之间的距离 */
}

.links {
  display: flex;
  align-items: center;
  margin-left: auto;
  /* 将链接置于父容器的右侧 */
}

.link {
  margin-right: 10px;
  /* 调整这个数值来调整链接之间的距离 */
}

table {
  border-collapse: collapse;
  margin-top: 20px;
  width: 100%;
}

th {
  border: 1px solid black;
  padding: 5px;
  text-align: left;
}

td {
  border: 1px solid black;
  padding: 5px;
}
</style>