<template>
    <div v-if="!$store.state.isdenglu">
        <h1>请先登录您的账号</h1>
        <router-link to="denglu">登录</router-link>
        <br>
        <router-link to="zhuce">现在注册</router-link>
    </div>
</template>
<script>
export default {
}
</script>
<style scoped></style>