<template>
	<footer>
	    <p>软笔字书法培训  &copy;  2021</p>
	</footer>
</template>

<script setup>
	
</script>

<style>
</style>