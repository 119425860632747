<template>
	<div  class="team-card">
	    <img  v-lazy="imgsrc" :alt="imgalt">
	    <h3><router-link :to='imgalt'>{{h3txt}}</router-link></h3>
	    <p>{{ptxt}}</p>
	</div>
</template>

<script>
	export default{
		props:{imgsrc:String,imgalt:String,h3txt:String,ptxt:String}
	}
</script>

<style>
	.team-card  {
	    margin-top:  50px;
	    text-align:  center;
	}
	
	
	.team-card  img  {
	    width:  200px;
	    height:  200px;
	    border-radius:  50%;
	    object-fit:  cover;
	    margin-bottom:  20px;
	}
	
	.team-card  h3  {
	    font-size:  24px;
	    margin:  0;
	}
	
	.team-card  p  {
	    margin:  20px  0;
	}
</style>