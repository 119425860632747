<template>
	<section class="contact">
		<h2>联系我们</h2>
		<div class="contact-form">
			<img v-lazy="weima" alt="图片未加载" />"
		</div>
	</section>
</template>

<script setup>
import { reactive, watchEffect } from "vue"
const weima = require("@/assets/images/weima.jpg")
let state = reactive({
	name: localStorage.getItem("name"),
	phone: localStorage.getItem("phone"),
	messagees: localStorage.getItem("msg")
})
watchEffect(() => {
	localStorage.setItem('name', state.name)
	localStorage.setItem("phone", state.phone)
	localStorage.setItem("msg", state.messagees)
})
</script>

<style scoped>
.contact {
	padding: 50px;
	background-color: #fff;
}

.contact h2 {
	font-size: 36px;
	margin: 0;
}

.contact-form {
	margin-top: 50px;
}

.contact-form label {
	display: block;
	margin-bottom: 10px;
}

.contact-form input,
.contact-form textarea {
	padding: 10px;
	border-radius: 5px;
	border: 1px solid #ccc;
	margin-bottom: 20px;
	width: 100%;
	box-sizing: border-box;
}

.contact-form textarea {
	height: 100px;
}

.contact-form button {
	border: none;
	background-color: #0066cc;
	color: #fff;
	padding: 10px 20px;
	font-size: 18px;
	border-radius: 30px;
}
</style>