<template>
	<section>
		<div class="color"></div>
		<div class="color"></div>
		<div class="color"></div>
		<div class="box">
			<div class="container">
				<div class="form">
					<h2>登录</h2>
					<form>
						<div class="inputBox">
							<input type="text" placeholder="请输入手机号" v-model="phone">
						</div>
						<div class="inputBox">
							<input type="password" placeholder="请输入密码" v-model="password">
						</div>
						<div class="inputBox">
							<input type="submit" value="登录" @click.prevent="validateForm">
						</div>
						<p class="forget"> 忘记密码 <router-link to="/findpassword">点击这里</router-link></p>
						<p class="forget"> 没有账号 <router-link to="/zhuce">立即注册</router-link></p>
					</form>
				</div>
			</div>
			<div class="box">
				<div class="square" style="--i:0"></div>
				<div class="square" style="--i:1"></div>
				<div class="square" style="--i:2"></div>
				<div class="square" style="--i:3"></div>
				<div class="square" style="--i:4"></div>
			</div>
		</div>
	</section>
</template>

<script>
import { denglu } from '@/store/denglu';
export default {
	data() {
		return {
			phone: '',
			password: ''
		}
	},
	methods: {
		validateForm() {
			if (this.phone === '' || this.password === '') {
				alert('有输入框为空，登录失败');
				return;
			}
			denglu(this.$store, this.phone,this.password)
			alert('登录成功');
			this.$router.push('/zhanghu');
		}
	}
}
</script>

<style scoped>
* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: 黑体;
}

section {
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 100vh;
	background: linear-gradient(to bottom, #f1f4f9, #dff1ff);
}

section .color {
	position: absolute;
}

section .color:nth-child(1) {
	top: -350px;
	width: 600px;
	height: 60px;
	background: #ff359b;
}

section .color:nth-child(2) {
	bottom: -150px;
	left: 100px;
	width: 500px;
	height: 500px;
	background: #fffd87;
}

section .color:nth-child(3) {
	bottom: 50px;
	right: 100px;
	width: 300px;
	height: 300px;
	background: #00dfff;
}

section .color {
	filter: blur(150px);
}

.container {
	position: relative;
	z-index: 10;
	/* 设置较高的层叠顺序值 */
	width: 400px;
	min-height: 400px;
	background: rgba(255, 255, 255, .1);
	border-radius: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
	backdrop-filter: blur(5px);
	box-shadow: 0 25px 45px rgba(0, 0, 0, .1);
	border: 1px solid rgba(255, 255, 255, .5);
	border-right: 1px solid rgba(255, 255, 255, .2);
	border-bottom: 1px solid rgba(255, 255, 255, .2);
}

.form {
	position: relative;
	width: 100%;
	height: 100%;
	padding: 40px;
}

.form h2 {
	position: relative;
	color: black;
	font-size: 24px;
	font-weight: 600;
	letter-spacing: 1px;
	margin-bottom: 40px;
}

.form h2::before {
	content: "";
	position: absolute;
	left: 0;
	bottom: -10px;
	width: 80px;
	height: 4px;
	background: #fff;
}

.form .inputBox {
	width: 100%;
	margin-top: 20px;
}

.form .inputBox input {
	width: 100%;
	background: rgba(255, 255, 255, .2);
	border: none;
	outline: none;
	padding: 10px 20px;
	border-radius: 35px;
	box-shadow: 0 25px 45px rgba(0, 0, 0, .5);
	border-right: 1px solid rgba(255, 255, 255, .2);
	border-bottom: 1px solid rgba(255, 255, 255, .2);
	letter-spacing: 1px;
	color: black;
	box-shadow: 0 5px 15px rgba(0, 0, 0, .05);
}

.form .inputBox input::placeholder {
	color: grey;
}

.form .inputBox input[type="submit"] {
	background: #fff;
	color: #666;
	max-width: 100px;
	cursor: pointer;
	margin-bottom: 20px;
	font-weight: 600;
}

.forget {
	margin-top: 5px;
	color: black;
}

.forget router-link {
	color: goldenrod;
	font-weight: 600;
}

.box {
	position: relative;
}

.box .square {
	position: absolute;
	width: 100px;
	height: 100px;
	background: rgba(255, 255, 255, .1);
	backdrop-filter: blur(5px);
	box-shadow: 0 25px 45px rgba(0, 0, 0, .1);
	border: 1px solid rgba(255, 255, 255, .5);
	border-right: 1px solid rgba(255, 255, 255, .2);
	border-bottom: 1px solid rgba(255, 255, 255, .2);
	border-radius: 10px;
}

.box .square:nth-child(1) {
	top: -50px;
	right: -60px;
	width: 100px;
	height: 100px;
}

.box .square:nth-child(2) {
	top: -450px;
	right: 400px;
	width: 150px;
	height: 150px;
}

.box .square:nth-child(3) {
	top: -20px;
	right: 260px;
	width: 80px;
	height: 80px;
}

.box .square:nth-child(4) {
	top: -350px;
	right: -60px;
	width: 120px;
	height: 120px;
}

.box .square:nth-child(5) {
	top: -80px;
	right: -140px;
	width: 60px;
	height: 60px;
}

.box .square {
	animation: animate 10s linear infinite;
	animation-delay: calc(-1s*var(--i));
}

@keyframes animate {

	0%,
	100% {
		transform: translateY(-40px);
	}

	50% {
		transform: translateY(40px);
	}
}</style>