<script setup>
// This starter template is using Vue 3 <script setup> SFCs
// Check out https://v3.vuejs.org/api/sfc-script-setup.html#sfc-script-setup
import DaoHang from './components/DaoHang.vue'
import footers from './components/mains/footers.vue'
import {denglu} from './store/denglu.js'
</script>
<template class="bodys">
    <DaoHang></DaoHang>
    <router-view />
    <footers></footers>
</template>
<script>
// import axios from 'axios'
export default {
    components: {
        DaoHang,
        footers
    },
    mounted() {
        if (this.$store.state.isdenglu) {
            this.getclass()
        } else {
            denglu(this.$store,localStorage.getItem('phone'),localStorage.getItem('password'), localStorage.getItem('registrationTime'))
            this.getclass()
            this.churudengji()
        }
    },
    methods: {
        getclass() {
            this.$axios.get(`${this.$store.state.url}/getclass`) // 替换为你的 GET 请求的 API 端点
                .then(response => { this.$store.state.courses = response.data })
                .catch(error => {this.$router.push({ name: 'linkfail' }) })
        },
        churudengji() {
            if (this.$store.state.isstudent) {
                this.$axios.post(this.$store.state.url + '/churujilu', postData, id)
                    .then(response => { this.$store.state.churudenglilist = response.data })
                    .catch(error => {this.$router.push({ name: 'linkfail' }) })
            } else {
                this.$axios.get(`${this.$store.state.url}/dianmingbiao?job=${this.$store.state.job}&id=${this.$store.state.data}`)
                    .then(response => { this.$store.state.churudenglilist = response.data })
                    .catch(error => { this.$router.push({ name: 'linkfail' }) })
            }
            console.log(this.$store.state.churudenglilist)
        }
    }
}

</script>
<style>
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    margin-top: 60px;
}

.bodys {
    font-family: Arial, sans-serif;
    font-size: 16px;
    line-height: 1.5;
    margin: 0;
    padding: 0;
}

a {
    color: #0066cc;
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}

/*  头部  */
header {
    background-color: #f2f2f2;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

h1 {
    font-size: 36px;
    margin: 0;
}

nav ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
}

nav li {
    margin-right: 20px;
}

nav a {
    display: block;
    padding: 10px;
}
</style>