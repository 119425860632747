<template>
	<div class="bds">
		<header>
			<nav>
				<ul>
					<li><router-link to="/">返回首页</router-link></li>
					<li><router-link to="jj1">高伟崴</router-link></li>
					<li><router-link to="jj2">高云英</router-link></li>
					<li><router-link to="jj3">冯国栋</router-link></li>
				</ul>
			</nav>
			<div class="banner">
				<img v-lazy="guodong" alt="个人头像">
				<h1>冯国栋</h1>
				<h3>无锡市锡山区书法家协会会员</h3>
			</div>
		</header>
		<main>
			<section id="about">
				<h2>个人简介</h2>
				<pre>自学成才的书法奖，曾入一次省展</pre>
			</section>
			<section id="certificate">
				<h2>证书</h2>
				<!-- <div class="certificate">
					<img v-for="p in item" v-lazy="p" alt="证书"/>
				</div> -->
			</section>
			<section id="exhibition">
				<h2>展览</h2>
				<!-- <div class="exhibitions">
					<img src="/src/assets/images/gz1.jpg" alt="展览">
					<img src="/src/assets/images/gz2.jpg" alt="展览">
					<img src="/src/assets/images/g2z1.jpg" alt="展览">
				</div> -->
			</section>
		</main>
		<footer>
			<p>版权信息</p>
		</footer>
	</div>
	
</template>

<script setup>
	const src="/src/assets/images/zs1"
	const item=[]
	for(var i=0;i<=7;i++){
		item.push(src+i+".jpg")
	}
	const guodong=require("@/assets/images/guodong.jpg")
</script>

<style scoped>
	.bds {
		margin: 0;
		padding: 0;
		font-family: 微软雅黑, sans-serif;
	}
	nav ul {
		list-style: none;
		margin: 0;
		padding: 0;
	}
	nav li {
		display: inline-block;
		margin: 10px;
	}
	nav li a {
		text-decoration: none;
		color: #fff;
		font-size: 16px;
	}
	header {
		background-color: #333;
		color: #fff;
	}
	.banner {
		margin: 50px 0;
		text-align: center;
	}
	.banner  img  {
	    width:  auto;
	    height:  300px;
	    border-radius:  50%;
	    margin-bottom:  10px;
	}
	.banner h1,
	.banner h3 {
		margin: 0;
	}
	.main {
		max-width: 960px;
		margin: 0 auto;
		padding: 30px;
	}
	#about {
		margin-bottom: 50px;
	}
	#about h2 {
		margin-top: 0;
	}
	#certificate img,
	#exhibition img {
		display: inline-block;
		width: 300px;
		height: 200px;
		margin-right: 20px;
		margin-bottom: 20px;
	}
	footer {
		text-align: center;
		background-color: #eee;
		padding: 10px;
	}
</style>