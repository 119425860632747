<template>
  <Qingdenglu/>
  <div class="sales-container" v-if="$store.state.isdenglu">
    <h1 class="title">购买交费记录表</h1>
    <table class="sales-table">
      <thead>
        <tr>
          <th>教育机构</th>
          <th>商品名称</th>
          <th>商品费用</th>
          <th>支付方式</th>
          <th>是否销货</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="sale in sales" :key="sale.id">
          <td>{{ sale.institution }}</td>
          <td>{{ sale.productName }}</td>
          <td>{{ sale.cost }}</td>
          <td>{{ sale.paymentMethod }}</td>
          <td>{{ sale.sold ? '是' : '否' }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import Qingdenglu from '../qingdenglu.vue';

export default {
    data() {
        return {
            sales: [
                {
                    id: 1,
                    institution: "ABC教育机构",
                    productName: "英语教材",
                    cost: "$50",
                    paymentMethod: "支付宝",
                    sold: true
                },
                // 添加更多的销售记录数据对象
            ]
        };
    },
    components: { Qingdenglu }
}
</script>

<style scoped>
.sales-container {
  margin: 20px;
}

.title {
  text-align: center;
}

.sales-table {
  width: 100%;
  border-collapse: collapse;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.sales-table th,
.sales-table td {
  padding: 12px 16px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.sales-table th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.sales-table tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}

.sales-table tbody tr:hover {
  background-color: #f5f5f5;
}
</style>