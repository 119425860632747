import { createStore } from 'vuex';

export default createStore({

	state: {
		// 在这里定义全局共享的状态
		url:'47.101.198.40',
		data: '',
		items: [], // 初始化为空数组，denglu操作后包含root表所有字段
		items2: [],//学生家长登录里面是remainingclass数组，教师登录是teacher数组，校长登录是rector数组
		name: "请登录",
		job: "xxx",
		familysid:'',
		isdenglu: false,
		iscourse: false,
		isstudent: false,
		isteacher: false,
		isrector:false,
		datachoose:'',
		jijiechoose:'',
		isfamilymember:false,
		familyMembers: [
			{ label: '爸爸', name: '', showOptions: false, options: [] , choose: null,job:'father'},
			{ label: '妈妈', name: '', showOptions: false, options: [] , choose: null,job:'mather'},
			{ label: '爷爷', name: '', showOptions: false, options: [] , choose: null,job:'grandfather'},
			{ label: '奶奶', name: '', showOptions: false, options: [] , choose: null,job:'grandmather'},
			{ label: '外公', name: '', showOptions: false, options: [] , choose: null,job:'mgrandfather'},
			{ label: '外婆', name: '', showOptions: false, options: [] , choose: null,job:'mgrandmather'},
			{ label: '孩子1', name: '', showOptions: false, options: [] , choose: null,job:'children1'},
			{ label: '孩子2', name: '', showOptions: false, options: [], choose: null ,job:'children2'},
			{ label: '孩子3', name: '', showOptions: false, options: [] , choose: null,job:'children3'},
			{ label: '孩子4', name: '', showOptions: false, options: [] , choose: null,job:'children4'},
		],
		churudenglilist:[],
		courses:[],
	},	
});