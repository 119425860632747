<template>
	<div  class="col">
	    <img  v-lazy="phsrc"  alt="书法作品1">
	    <h3>{{h3s}}</h3>
	    <p>{{ptxt}}</p>
	</div>
</template>

<script>
	export default{
		props:{phsrc:String,
		h3s:String,
		ptxt:String}
	}
</script>

<style scoped>
	.col  {
	    flex:  1  0  33.3%;
	    padding:  20px;
	    text-align:  center;
	    box-sizing:  border-box;
	}
	
	.col  img  {
	    max-width:  100%;
	    height:  auto;
	    border-radius:  5px;
	    box-shadow:  0  0  5px  rgba(0,0,0,0.2);
	    transition:  all  0.3s  ease-in-out;
	    cursor:  pointer;
	    min-width:  100%;
	}
	
	.col  img:hover  {
	    transform:  scale(1.1);
	    box-shadow:  0  0  20px  rgba(0,0,0,0.3);
	}
	
	.col  h3  {
	    margin-top:  15px;
	    font-size:  24px;
	    color:  #333333;
	}
	
	.col  p  {
	    margin-top:  10px;
	    font-size:  16px;
	    color:  #999999;
	}

</style>