<template>
	<div  class="works-card">
	    <img  v-lazy="imgsrc"  :alt="imgalt">
	</div>
</template>

<script>
	export default{
		props:{imgsrc:String,imgalt:String}
	}
</script>

<style scoped>
	.works-card  {
	    margin-top:  50px;
	    flex-basis:  calc(25%  -  20px); 
	}
	
	.works-card  img  {
	    width:  100%;
	    object-fit:  cover;
	    border-radius:  5px;
	}
</style>