<template>
  <Qingdenglu></Qingdenglu>
  <div v-if="$store.state.isdenglu">
    <table class="family-table">
      <thead>
        <tr>
          <th>关系</th>
          <th>姓名</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in  $store.state.familyMembers" :key="index">
          <td>{{ item.label }}</td>
          <td>{{ item.name }}</td> <!--  -->
          <td><router-link to="gaifamily">修改</router-link></td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
  
<script>
import axios from 'axios';
import Qingdenglu from '../qingdenglu.vue'
export default {
    name: 'FamilyTable',
    mounted() {
      
    },
    methods: {
      
    },
    components: { Qingdenglu }
}
</script>
  
<style>
.family-table {
  width: 100%;
  border-collapse: collapse;
  font-family: Arial, sans-serif;
  color: #333;
}

.family-table th,
.family-table td {
  padding: 10px;
  border: 1px solid #ccc;
}

.family-table th {
  background-color: #f2f2f2;
  font-weight: bold;
  text-align: left;
}

.family-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.family-table td {
  vertical-align: middle;
}

.family-table td:first-child {
  font-weight: bold;
  font-size: 16px;
}

.family-table td.name {
  font-size: 14px;
  font-weight: normal;
}

.family-table tr:last-child {
  border-bottom: none;
}
</style>