<template>
  <div class="container">
    <h1>密码重置</h1>
    <form id="resetForm">
      <div class="form-group">
        <label for="phoneNumber">手机号:</label>
        <input v-model="phone" type="text" name="phoneNumber" placeholder="请输入手机号">
      </div>
      <div class="form-group">
        <label for="verificationCode">验证码:</label>
        <input v-model="phonecode" type="text" name="verificationCode" placeholder="请输入验证码">
        <button type="button" @click="sendVerificationCode">获取验证码</button>
      </div>
      <div class="form-group">
        <label for="newPassword">新密码:</label>
        <input v-model="newpassword" type="password" name="newPassword" placeholder="请输入新密码">
      </div>
      <button type="submit">确认</button>
    </form>
  </div>
</template>

<script>
	export default {
		data() {
			return{
				phone:"",
				phonecode:"",
				newpassword:""
			}
		},
	  methods: {
	    resetPassword() {
	      // 清空错误和成功信息
	      // 发送密码重置请求
	      axios.post("/reset-password", {
	        phone: this.phone,
	        phonecode: this.phonecode,
	        newpassword: this.newpassword
	      })
	      .then(function(response) {
	        console.log(response.data);
	        document.getElementById("successMessage").innerText = "密码重置成功！";
	      })
	      .catch(function(error) {
	        console.log(error.response.data);
	        document.getElementById("errorMessage").innerText = "密码重置失败，请稍后再试！";
	      });
	    },
		 sendVerificationCode() {
			 
		      // 实现发送验证码的逻辑
		}
	  }
	}
</script>

<style scoped>
	 template {
	      font-family: Arial, sans-serif;
	      background-color: #f5f5f5;
	      padding: 20px;
	      text-align: center;
	    }
	
	    h1 {
	      color: #333;
	    }
	
	    form {
	      max-width: 300px;
	      margin: 0 auto;
	      padding: 20px;
	      background-color: #fff;
	      border-radius: 5px;
	      box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
	    }
	
	    label {
	      display: block;
	      margin-bottom: 10px;
	      color: #555;
	      text-align: left;
	    }
	
	    input[type="text"],
	    input[type="password"] {
	      width: 100%;
	      padding: 10px;
	      border: 1px solid #ccc;
	      border-radius: 4px;
	    }
	
	    .error-message {
	      color: red;
	      font-size: 14px;
	      margin-top: 5px;
	      text-align: left;
	    }
	
	    .success-message {
	      color: green;
	      font-size: 14px;
	      margin-top: 5px;
	      text-align: left;
	    }
	
	    button {
	      display: block;
	      width: 100%;
	      padding: 10px;
	      margin-top: 20px;
	      background-color: #4CAF50;
	      color: #fff;
	      border: none;
	      border-radius: 4px;
	      cursor: pointer;
	    }
	
	    button:hover {
	      background-color: #45a049;
	    }
</style>