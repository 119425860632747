<template>
  <Qingdenglu />
  <div class="container" v-if="$store.state.isdenglu">
    <h1>素质之星比赛报名表</h1>
    <form id="registrationForm">
      <div class="form-group">
        <label for="name">姓名</label>
        <select name="name" id="name" v-model="job" required>
          <option value=""></option>
          <option v-for="(member, index) in $store.state.familyMembers.slice(-4)" :key="index" :value="member.job">{{
            member.name }}</option>
        </select>
      </div>
      <div class="form-group">
        <label for="idCard">身份证号:</label>
        <input type="text" id="idCard" name="idCard" v-model="sid" required>
      </div>
      <div class="form-group">
        <label for="age">年龄（周岁）:</label>
        <input type="number" id="age" name="age" v-model="age" required>
      </div>
      <div class="form-group">
        <label for="subject">比赛科目:</label>
        <select name="subject" id="subject" v-model="courseid" required>
          <option value=""></option>
          <option v-for="member in $store.state.courses" :key="member.id" :value="member.id">{{ member.name }}</option>
        </select>
      </div>
      <div><datachoose></datachoose></div>
      <button type="submit" @click.prevent="tijiao">提交报名</button>
    </form>
  </div>
</template>

<script>
import Qingdenglu from '../qingdenglu.vue';
import datachoose from './datachoose.vue'
export default {
  name: 'RegistrationForm',
  components: { Qingdenglu, datachoose},
  data() {
    return {
      courseid: "",
      job: "",
      sid: "",
      age: "",
      time:""
    }
  },
  methods: {
    tijiao() {
      const params = {
        course:this.courseid,
        familyid:this.$store.state.familysid,
        job:this.job,
        sid:this.sid,
        age:this.age,
        time:this.$store.state.datachoose+this.$store.state.jijiechoose
      }
      axios.get('http://localhost:8080/suzhizhixing', { params }) // 替换为你的 GET 请求的 API 端点
        .then(response => {
          if (response.data == 'success') {
            this.$forceUpdate
            alert('报名成功，可以开始创作了')
          }else if(response.data=='22'){
            alert('请勿重复报名，报名失败')
          }
        })
        .catch(error => { this.$router.push({ name: 'linkfail' }) });
    }
  }
}
</script>

<style scoped>
.container {
  max-width: 400px;
  margin: 50px auto;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

}

h1 {
  text-align: center;
  margin-bottom: 30px;
}

.form-group {
  margin-bottom: 20px;
}

label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

input[type="text"],
input[type="number"] {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 3px;
}

button[type="submit"] {
  display: block;
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: #fff;
  font-weight: bold;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}

button[type="submit"]:hover {
  background-color: #0056b3;
}
</style>