<template>
  <Qingdenglu/>
  <div class="payment-table-container" v-if="$store.state.isdenglu">
	  <h1>课时交费记录表</h1>
    <table class="payment-table">
      <thead>
        <tr>
          <th>姓名</th>
          <th>教育机构</th>
          <th>课程</th>
          <th>费用</th>
          <th>交费时间</th>
          <th>课时</th>
          <th>交费方式</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="payment in paymentData" :key="payment.id">
          <td>{{ payment.name }}</td>
          <td>{{ payment.institution }}</td>
          <td>{{ payment.course }}</td>
          <td>${{ payment.amount }}</td>
          <td>{{ payment.date }}</td>
          <td>{{ payment.hours }}</td>
          <td>{{ payment.paymentMethod }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import Qingdenglu from '../qingdenglu.vue';

export default {
    data() {
        return {
            paymentData: [
                {
                    id: 1,
                    name: "张三",
                    institution: "ABC教育机构",
                    course: "数学",
                    amount: 1000,
                    date: "2023-09-01",
                    hours: 10,
                    paymentMethod: "支付宝"
                },
                // 添加更多的数据对象
            ]
        };
    },
    components: { Qingdenglu }
}
</script>

<style scoped>
.payment-table-container {
  margin: 20px;
}

.payment-table {
  width: 100%;
  border-collapse: collapse;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.payment-table th,
.payment-table td {
  padding: 12px 16px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.payment-table th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.payment-table tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}

.payment-table tbody tr:hover {
  background-color: #f5f5f5;
}
</style>