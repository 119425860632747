<template>
    <select v-model="$store.state.datachoose" required> 
        <option value=""></option>
        <option v-for="(p,index) in year" :key="index" value="p">{{p}}</option>
    </select>
    <select v-model="$store.state.jijiechoose" required> 
        <option value=""></option>
        <option v-for="(p,index) in jijie" :key="index" value="p">{{p}}</option>
    </select>
</template>

<script>
export default {
  data() {
    return {
      year: [],
      jijie: ['春', '夏', '秋', '冬']
    }
  },
  created() {
    const currentYear = new Date().getFullYear();
    for (let i = currentYear; i <= 2100; i++) {
      this.year.push(String(i));
    }
  }
};
</script>
<style scoped>
select {
  width: 31%;
  padding: 10px;
  margin-right: 3%;
  box-sizing: border-box;
  border: 2px solid #ddd;
  border-radius: 4px;
}

label {
  display: block;
  margin-bottom: 10px;
  font-weight: bold;
}
</style>