<template>
  <Qingdenglu/>
  <div class="competition-results-container" v-if="$store.state.isdenglu">
    <h1 class="title">比赛交费记录表</h1>
    <table class="competition-results-table">
      <thead>
        <tr>
          <th>姓名</th>
          <th>教育机构</th>
          <th>比赛名称</th>
          <th>比赛科目</th>
          <th>比赛时间</th>
          <th>报名费用</th>
          <th>比赛组别</th>
          <th>比赛成绩</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="result in competitionResults" :key="result.id">
          <td>{{ result.name }}</td>
          <td>{{ result.institution }}</td>
          <td>{{ result.competitionName }}</td>
          <td>{{ result.competitionSubject }}</td>
          <td>{{ result.date }}</td>
          <td>{{ result.registrationFee }}</td>
          <td>{{ result.group }}</td>
          <td>{{ result.score }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import Qingdenglu from '../qingdenglu.vue';
export default {
    data() {
        return {
            competitionResults: [
                {
                    id: 1,
                    name: "张三",
                    institution: "ABC教育机构",
                    competitionName: "数学竞赛",
                    competitionSubject: "数学",
                    date: "2023-09-15",
                    registrationFee: "$50",
                    group: "初级组",
                    score: "90"
                },
                // 添加更多的数据对象
            ]
        };
    },
    components: { Qingdenglu }
}
</script>

<style scoped>
.competition-results-container {
  margin: 20px;
}

.title {
  text-align: center;
}

.competition-results-table {
  width: 100%;
  border-collapse: collapse;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.competition-results-table th,
.competition-results-table td {
  padding: 12px 16px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.competition-results-table th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.competition-results-table tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}

.competition-results-table tbody tr:hover {
  background-color: #f5f5f5;
}
</style>