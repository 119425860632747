// StudentAttendanceRecord.vue
<template>
  <table v-if="$store.state.isstudent">
    <thead>
      <tr>
        <th>学生姓名</th>
        <th>学校</th>
        <th>班级</th>
        <th>考勤时间</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="record in $store.state.churudenglilist" :key="record.id">
        <td>{{ record.nameid }}</td>
        <td>{{ record.schoolid }}</td>
        <td>{{ record.courseid }}</td>
        <td>{{ record.year + '年' + record.month + '月' + record.data + '日' + record.week }}</td>
      </tr>
    </tbody>
  </table>
  <div v-if="!$store.state.isstudent">
    <div>
      <label>选择年份: <input v-model="year" type="number" min="2024" /></label>
      <label>选择月份: <input v-model="month" type="number" min="1" max="12" /></label>
    </div>
    <table>
      <thead>
        <tr>
          <th>学生姓名</th>
          <th>学校</th>
          <th>班级</th>
          <th v-for="(date, index) in week" :key="index">{{ date + '日' }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="record in $store.state.churudenglilist" :key="record.id">
          <td>{{ record.nameid }}</td>
          <td>{{ record.schoolid }}</td>
          <td>{{ record.courseid }}</td>
          <td v-for="date in days" :key="date">{{ date }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>


<script>
export default {
  data() {
    return {
      year: new Date().getFullYear(),
      month: new Date().getMonth() + 1,
      week: ['星期一', '星期二', ' 星期三', '星期四', '星期五', '星期六', '星期天'],
      churulist: [],
      days: [],
    };
  },
  // watch: {
  //   year: function (newYear) {
  //     this.gengxing()
  //   },
  //   month: function (newMonth) {
  //     this.gengxing()
  //   },
  //   weekchoose: function (newchoose) {
  //     this.gengxing()
  //   }
  // },
  // methods: {
  //   gengxing() {
  //     this.churulist = []
  //     this.days = []
  //     for (p in this.$store.state.churudenglilist) {
  //       if (p.year == this.year && p.month == this.month && p.week == this.weekchoose) {
  //         this.churulist += p
  //         if (!this.days.includes(p.data)) {
  //           this.days += p.data
  //         }
  //       }
  //     }
  //   }
  // },
  computed: {
    //创建一个数组将churulist中所有的数据按日期分组然后根据courseid分组，然后根据schoolid分组
    // records() {
    //   let records = []
    //   let churulist = this.churulist
    //   for (p in churulist) {
    //     if (!records.includes(churulist[p].courseid)) {
    //       records.push({ courseid: churulist[p].courseid, schoolid: churulist[p].schoolid, name: churulist[p].nameid })
    //     }
    //   }
    //   return records
    // }
  }
}

</script>

<style scoped>
table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
}

th {
  background-color: #4CAF50;
  color: white;
}

tr:nth-child(even) {
  background-color: #f2f2f2;
}

input[type="number"] {
  width: 60px;
  margin-right: 10px;
}
</style>