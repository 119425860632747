<template>
	<section  class="team">
	    <h2>教学团队</h2>
	    <div class="team-list">
	        <team1 v-for="(p,index) in item" :key="index" :imgsrc="p.imgsrc" :imgalt="p.imgalt" :ptxt="p.ptxt" :h3txt="p.h3txt"></team1>
	    </div>
	</section>
	<section  class="works">
	    <h2>学员作品</h2>
	    <div  class="works-list">
	        <works v-for="(p,index) in item2" :key="index" :imgsrc="p.imgsrc" :imgalt="p.imgalt"></works>
	    </div>
	</section>
</template>

<script setup>
	import team1 from './tramses/team1.vue'
	import works from './tramses/works.vue'
	components:['team1','works']
	const item = [
	  {imgsrc: require('@/assets/images/teacher1.jpg'), imgalt:"jj2", h3txt:"高云英", ptxt:"无锡市书法家协会会员，持多项市级省级书法大赛奖项。"},
	  {imgsrc: require('@/assets/images/teacher2.jpg'), imgalt:"jj3", h3txt:"冯国栋", ptxt:"无锡市书法家协会会员，持多项市级区级书法大赛奖项。"},
	  {imgsrc: require('@/assets/images/teacher3.jpg'), imgalt:"jj1", h3txt:"高伟崴", ptxt:"江苏省书法家协会会员，持多项市级书法大赛奖项。"}
	]
	const item2=[{imgsrc:require("@/assets/images/work1.jpg"),imgalt:"作品1"},
	{imgsrc:require("@/assets/images/work2.jpg"),imgalt:"作品2"},
	{imgsrc:require("@/assets/images/work3.jpg"),imgalt:"作品3"},
	{imgsrc:require("@/assets/images/work4.jpg"),imgalt:"作品4"}]
</script>

<style scoped>
	.team  {
	    padding:  50px;
	    background-color:  #fff;
	}
	
	.team  h2  {
	    font-size:  36px;
	    margin:  0;
	}
	
	
	
	.works  {
	    padding:  50px;
	    background-color:  #f2f2f2;
	}
	
	.works  h2  {
	    font-size:  36px;
	    margin:  0;
	}
	
	.works-list  {
	    display:  flex;
	    justify-content:  space-between;
	    flex-wrap:  wrap;
	    margin-top:  50px;
	}
	
	
	
</style>