<template>
	<div>
		<router-link class="routerlink" :to="hrefs">{{name}}</router-link>
	</div>
</template>

<script>
	export default{
		props:{name:String,
		hrefs:String}
	}
	
</script>

<style scoped>
	.routerlink{
		color: black;
	}
</style>