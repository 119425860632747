<template>
	<div class="bodys">
		<h1>课程展览</h1>
		<div class="container">
			<div v-for="(p,index) in item" :key="index" class="course">
				<h2>{{p.titl}}</h2>
				<p>{{p.str}}</p>
			</div>
		</div>
		<div class="photo-gallery">
			<div v-for="(p,index) in item2" :key="index" class="photo">
				<img  v-lazy="p" alt="学生上课照片">
			</div>
		</div>
	</div>
	
</template>

<script setup>
	const item=[{titl:"软笔书法",str:"熟练掌握毛笔字的使用方法，使学生能够用毛笔正常书写繁体字，培训包括楷书，隶书，行书等多种字体，带领学生参加省，市级比赛和展览"},
	{titl:"硬笔书法",str:"改善小朋友字迹，写字坐姿，握笔姿势，学习状态，使其能够写好一手漂亮的硬笔字"},
	{titl:"绘画课",str:"拓展孩子兴趣爱好，发展国画，油画，彩铅，素描等爱好的小朋友"},
	{titl:"竹笛课",str:"培训大量乐谱，乐理知识，提高音乐鉴赏能力，发展小朋友管弦乐器天赋，积极参加考级"}]
	const requireImg = require.context('@/assets/images', false, /\.(png|jpe?g|gif|svg)$/)
	const item2 = Array.from({ length: 44 }, (_, index) => requireImg(`./xx${index + 1}.jpg`))

</script>

<style scoped>
	.bodys{
				font-family: Arial, sans-serif;
				color: #333;
				padding: 20px;
				margin: 0;
			}
			h1{
				font-size: 36px;
				margin-top: 0;
				margin-bottom: 20px;
				text-align: center;
				text-transform: uppercase;
			}
			.container{
				max-width: 960px;
				margin: 0 auto;
				display: flex;
				flex-wrap: wrap;
				justify-content: center;
			}
			.course{
				width: 450px;
				margin: 20px;
				padding: 20px;
				border: 1px solid #ccc;
				border-radius: 10px;
				box-shadow: 2px 2px 5px #ccc;
			}
			.course h2{
				font-size: 24px;
				margin-top: 0;
			}
			.course p{
				font-size: 18px;
				line-height: 1.5;
			}
			.photo-gallery{
				margin-top: 50px;
				display: flex;
				flex-wrap: wrap;
				justify-content: center;
			}
			.photo{
				width: 200px;
				height: 200px;
				margin: 20px;
				overflow: hidden;
				border-radius: 50%;
				box-shadow: 2px 2px 5px #ccc;
			}
			.photo img{
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
</style>