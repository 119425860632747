<template>
	<div  class="course-card">
	    <h3>{{name}}</h3>
	    <p>{{des}}</p>
	    <a  :href="hrefs">了解更多</a>
	</div>
</template>

<script>
	export default{
		props:{name:String,des:String,hrefs:String}
	}
</script>

<style>
	.course-card  {
	    background-color:  #fff;
	    box-shadow:  0  0  5px  rgba(0,  0,  0,  0.1);
	    padding:  30px;
	    width:  calc(33.33%  -  20px);
	    box-sizing:  border-box;
	    text-align:  center;
	}
	
	.course-card  h3  {
	    font-size:  24px;
	    margin:  0;
	}
	
	.course-card  p  {
	    margin:  20px  0;
	}
</style>