<template>
  <Qingdenglu/>
  <div class="exam-results-container" v-if="$store.state.isdenglu">
    <h1 class="title">考试交费记录表</h1>
    <table class="exam-results-table">
      <thead>
        <tr>
          <th>姓名</th>
          <th>教育机构</th>
          <th>考级科目</th>
          <th>报考等级</th>
		  <th>考级费用</th>
          <th>考级时间</th>
          <th>是否通过</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="result in examResults" :key="result.id">
          <td>{{ result.name }}</td>
          <td>{{ result.institution }}</td>
          <td>{{ result.subject }}</td>
          <td>{{ result.grade }}</td>
		  <td>{{result.money}}</td>
          <td>{{ result.date }}</td>
          <td>{{ result.passed ? '是' : '否' }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import Qingdenglu from '../qingdenglu.vue';

export default {
    data() {
        return {
            examResults: [
                {
                    id: 1,
                    name: "张三",
                    institution: "ABC教育机构",
                    subject: "数学",
                    grade: "初级",
                    money: 300,
                    date: "2023-09-10",
                    passed: true
                },
                // 添加更多的数据对象
            ]
        };
    },
    components: { Qingdenglu }
}
</script>

<style scoped>
.exam-results-container {
  margin: 20px;
}

.title {
  text-align: center;
}

.exam-results-table {
  width: 100%;
  border-collapse: collapse;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.exam-results-table th,
.exam-results-table td {
  padding: 12px 16px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.exam-results-table th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.exam-results-table tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}

.exam-results-table tbody tr:hover {
  background-color: #f5f5f5;
}
</style>