<template>
	<div class="bodys">
	    <h1>学生书法作品展示</h1>
	    <div  class="container">
	        <div  class="row">
	            <zuopingzhanshi v-for="(p,index) in item" :key="index" :phsrc="p"></zuopingzhanshi>
	        </div>
	    </div>
	</div>
</template>

<script setup>
	// const str="/src/assets/images/work"
	// const item=[]
	// for( var i = 1; i <16  ; i ++ ){
	// 	item.push(str+i+".jpg")
	// }
	const requireImg = require.context('@/assets/images', false, /\.(png|jpe?g|gif|svg)$/)
	const item = Array.from({ length: 15}, (_, index) => requireImg(`./work${index+1}.jpg`))
	
	import zuopingzhanshi from './zuppinzhanshi.vue'
	components:['zuopingzhanshi']
	
</script>

<style scoped>
	  .bodys  {
	            background-color:  #f3f3f3;
	            font-family:  Arial,  sans-serif;
	        }
	        h1  {
	            text-align:  center;
	            margin-top:  50px;
	            font-size:  48px;
	            color:  #333333;
	        }
	        .container  {
	            max-width:  960px;
	            margin:  0  auto;
	            padding:  50px;
	            background-color:  #ffffff;
	            box-shadow:  0  0  10px  rgba(0,  0,  0,  0.2);
	        }
	        .row  {
	            display:  flex;
	            flex-wrap:  wrap;
	            margin:  0  -20px;
	        }
	       
</style>