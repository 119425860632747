<template>
    <div class="recharge-page">
      <h2>充值页面</h2>
      <div class="form-group">
        <label for="child-select">选择孩子：</label>
        <select id="child-select" v-model="selectedChild">
          <option v-for="(child, index) in children" :key="index" :value="child">{{ child }}</option>
        </select>
      </div>
      <div class="form-group">
        <label for="course-select">选择课程：</label>
        <select id="course-select" v-model="selectedCourse">
          <option v-for="(course, index) in courses" :key="index" :value="course">{{ course }}</option>
        </select>
      </div>
      <button class="recharge-btn" @click="recharge">充值</button>
    </div>
  </template>
  
  <script>
  export default {
    name: 'RechargePage',
    data() {
      return {
        children: ['孩子1', '孩子2', '孩子3', '孩子4'], // 孩子的姓名数组
        selectedChild: '', // 选中的孩子
        courses: ['课程1', '课程2', '课程3', '课程4'], // 教育机构的课程数组
        selectedCourse: '', // 选中的课程
      }
    },
    methods: {
      recharge() {
        if (this.selectedChild && this.selectedCourse) {
          // 执行充值逻辑，例如提交表单数据或发起网络请求
          console.log(`给 ${this.selectedChild} 充值课程 ${this.selectedCourse}`);
          alert(`给 ${this.selectedChild} 充值课程 ${this.selectedCourse}`);
        } else {
          alert('请选择孩子和课程');
        }
      }
    }
  }
  </script>
  
  <style>
  .recharge-page {
    max-width: 400px;
    margin: 0 auto;
    padding: 30px;
    font-family: Arial, sans-serif;
    background-color: #f9f9f9;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  label {
    font-weight: bold;
    margin-right: 5px;
  }
  
  select {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .recharge-btn {
    display: block;
    width: 100%;
    padding: 10px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .recharge-btn:hover {
    background-color: #45a049;
  }
  </style>